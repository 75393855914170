import React from "react";
import { Link } from "gatsby";
import { StructuredText } from "react-datocms";
import theme from "../styles/theme";

const Features = ({ data, mt, mb }) => {
  return (
    <section
      css={`
        margin-top: ${mt || `0`};
        margin-bottom: ${mb || `0`};
        .card {
          display: flex;
          width: 100%;
          box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.25);
          border-radius: 10px;
          padding: 40px;
          flex-wrap: wrap;
          box-sizing: border-box;
          ${theme.mobile} {
            padding: 20px;
          }
          p {
            font-size: 16px;
          }
        }
        .lead {
          flex: 2;
          padding-right: 20px;
          padding-bottom: 20px;
          p {
            max-width: 370px;
          }
          h1,
          h2,
          h3 {
            font-size: 48px;
            color: ${theme.grey};
            margin-bottom: 16px;
          }
          .button {
            margin-top: 20px;
            margin-bottom: 60px;
          }
          ${theme.max700} {
            flex-basis: 100%;
          }
        }
        .grid {
          flex: 3;
          display: grid;
          grid-gap: 20px;
          grid-template-columns: repeat(3, 1fr);
          ${theme.max700} {
            grid-template-columns: repeat(2, 1fr);
          }
          ${theme.max500} {
            grid-template-columns: repeat(1, 1fr);
          }
          .item {
            margin-top: 40px;
            h4 {
              font-weight: 900;
              margin-bottom: 0;
            }
            img {
              width: 70%;
              max-width: 140px;
            }
            p {
              margin-top: 12px;
            }
          }
        }
      `}
    >
      <div className="card">
        <div className="lead">
          <StructuredText
            data={data.features}
            renderBlock={({ record }) => {
              switch (record.__typename) {
                case "DatoCmsButton":
                  let cssClass = "default";
                  if (record.layout === "Pink Filled") cssClass = "pinkFilled";
                  if (record.layout === "Pink Outlines")
                    cssClass = "pinkOutlines";
                  if (record.layout === "Grey Outlines")
                    cssClass = "greyOutlines";

                  return (
                    <div>
                      <Link className={`button ${cssClass}`} to={record.url}>
                        {record.text}
                      </Link>
                    </div>
                  );
                default:
                  return null;
              }
            }}
          />
        </div>
        <div className="grid">
          {data.featuresItems.map((item, index) => {
            let iconUrl = item.icon.url.split("?").shift();
            return (
              <div className="item">
                <img src={iconUrl} alt={item.title} />
                <h4>{item.title}</h4>
                <p>{item.content}</p>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Features;
